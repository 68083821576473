<template>
    <div class="warp" v-loading="Loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="tab tab-top">
            <div class="tab-item toptab active">夺宝</div>
            <div class="tab-item toptab" :class="{ hiddenState: info.config.integralState == 0 }">
                夺宝商城
            </div>
            <div class="tab-item toptab" :class="{ hiddenState: info.config.type == 1 }">
                充值兑换
            </div>
            <div>
                <p style="padding: 3px 6px; float: left; margin-left: 14px">
                    <span>夺宝机会：{{ info.indiana_opportunity }}
                        <span v-if="info.config.type == 2" style="color: #797979"
                            @click="PayDisplay = 'display:block'">购买</span>

                        <el-tooltip v-if="info.config.type == 1" class="item" effect="dark" content=""
                            placement="bottom">
                            <div slot="content" style="padding: 10px 10px">
                                <div style="text-align: center; margin-bottom: 10px">
                                    挑战以下任意一个副本即可获得
                                </div>
                                <p v-for="(vcc, i) in info.config.roomdata" :key="i">
                                    <span style="color: #ce4f4f">通关【{{ vcc.name }}】</span>
                                </p>
                            </div>
                            <span style="color: #797979" @click="PayDisplay = 'display:block'">获得</span>
                        </el-tooltip>
                    </span>
                </p>
                <p v-if="info.config.integralState == 1" class=""
                    style="padding: 3px 6px; float: left; margin-left: 14px">
                    <span>积分：{{ info.indiana_integral }}</span>
                </p>
            </div>
            <div v-if="info.charac_name == ''" style="float: right; padding: 3px 0px">
                <span>绑定角色:</span>
                <span style="color: #9e9797" @click="LoginDisplay = 'display:block'">《去绑定》</span>
            </div>
            <div v-else style="float: right; padding: 3px 0px">
                <span>绑定角色:{{ info.charac_name }}</span>
                <span style="color: #9e9797" @click="LoginDisplay = 'display:block'">《去换绑》</span>
            </div>
        </div>

        <div class="main">
            <div class="page page-1 active">
                <div>
                    <div style="padding: 100px 96px">
                        <div class="luck lotteryBoxs" id="lotteryBoxs">
                            <div v-if="info.indiana.length >= 1">
                                <div v-for="(item, i) in info.indiana" :key="i" class="lc-prop" :class="item.index">
                                    <div class="lj" v-bind:itme-name="i">
                                        <img :src="item.ioc" alt="" v-on:mouseover="lj_info($event, item.code)"
                                            v-on:mouseout="removeActive($event)" />
                                    </div>
                                    <p class="name">{{ item.name }}</p>
                                </div>
                            </div>
                            <div v-else>
                                <div v-for="i in 12" :key="i" class="lc-prop" :class="`lc-prop-${i - 1}`">
                                    <div><img src="" alt="" /></div>
                                    <p class="name">未设置奖励</p>
                                </div>
                            </div>
                        </div>
                        <div class="tool-bar">
                            <div class="btn-bar">
                                <div v-if="click == false">
                                    <span style="
                      background: #005aff;
                      padding: 10px 30px;
                      color: #fff;
                      font-family: inherit;
                      font-size: 20px;
                      border-radius: 2px;
                      cursor: pointerpointer;
                      margin: 0px 2px; ;
                    " @click="luckyDraw(1, 1)">单抽</span>
                                    <span
                                        style="background:#005aff;padding:10px 30px;color:#fff;font-family:inherit;font-size:20px;border-radius:2px;cursor:pointer;pointer;margin:0px 2px;"
                                        @click="luckyDraw(1, 2)">十连抽</span>
                                </div>
                                <div v-else>
                                    <span style="
                      background: rgb(239 9 9);
                      padding: 10px 30px;
                      color: #fff;
                      font-family: inherit;
                      font-size: 20px;
                      border-radius: 2px;
                      cursor: pointer;
                      margin: 0px 2px;
                    ">单抽</span>
                                    <span style="
                      background: rgb(239 9 9);
                      padding: 10px 30px;
                      color: #fff;
                      font-family: inherit;
                      font-size: 20px;
                      border-radius: 2px;
                      cursor: pointer;
                      margin: 0px 2px;
                    ">十连抽</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page page-2">
                <div>
                    <div style="display: inline-block; float: left; width: 94%">
                        <div style="padding: 44px 10px 0px 62px">
                            <div style="
                  padding: 10px;
                  border: 2px solid #333333;
                  margin-left: 4px;
                ">
                                <ul class="letterLi">
                                    <li v-for="(item, i) in info.shop" :key="i">
                                        <div class="ljshow" style="display: inline-block; vertical-align: middle">
                                            <img :src="item.ioc" v-on:mouseover="lj_info($event, item.code)"
                                                v-on:mouseout="removeActive($event)"
                                                style="width: 38px; height: 38px" />
                                        </div>
                                        <div style="
                        display: inline-block;
                        vertical-align: top;
                        width: 60%;
                        margin-left: 10px;
                      ">
                                            <p>{{ item.name }}</p>
                                            <p>积分：{{ item.num }}</p>
                                        </div>
                                        <div style="display: inline-block; margin-left: 18px">
                                            <span style="
                          background: #b2bd04;
                          padding: 4px 9px;
                          border-radius: 2px;
                          color: #0e0e0e;
                        " @click="storebuy(item.id)">兑换</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page page-3">
                <div class="col-sm-12" style="margin-top: 4px; text-align: center">
                    <p style="padding: 84px 0px">
                        <input v-model="cdk" placeholder="请输入充值卡密" class="service__input" style="
                height: 36px;
                width: 410px;
                border: 1px solid #678267;
                background: #c5b48e;
              " /><span @click="search" style="
                cursor: pointer;
                padding: 13px 14px;
                background: rgb(155 109 0);
                border: 1px solid #000;
                border-radius: 3px;
              ">
                            兑换</span>
                    </p>
                </div>
                <div class="tr">
                    <span class="th-a">卡密</span><span>面值</span><span style="width: 200px">兑换时间</span>
                </div>
                <table style="margin-left: 80px; width: 79%; height: 204px">
                    <tr class="tt" v-for="(v, i) in info.plug_indian_camilo_log" :key="i">
                        <td class="td-a">@{{ v.camilo }}</td>
                        <td>{{ v.diamond }}</td>
                        <td style="width: 190px">{{ v.time }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="iteminfo">
            <div style="text-align: center">物品礼包</div>
            <div v-for="(itmec, i) in itme_show_data" :key="i" style="padding: 0px 6px">
                {{ itmec.name }}x{{ itmec.num }}
            </div>
        </div>
        <div class="alert">
            <!-- 购买 -->
            <div class="alert_bg" :style="PayDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>复制本链接到浏览器中打开</span>
                            <input style="width: 96%" type="text" :value="info.config.pay_url" />
                            <div class="btn" @click="PayDisplay = 'display:none'" style="margin-top: 10px">
                                关闭
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 弹出框 -->
            <div class="alert_bg" :style="MessageDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn" @click="CloseAlert">关闭</div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 弹出框end -->
            <!-- 登陆框 -->
            <div class="alert_bg" :style="LoginDisplay">
                <div class="alert_main" style="margin-top: 86px; width: 350px">
                    <div class="alert_title"><span>绑定|切换</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <p style="margin-bottom: 6px; display: flex">
                                <span style="padding: 0px 0px 0px 42px">游戏账号：</span><input type="text"
                                    v-model="login_info.username" class="service__input" />
                            </p>
                            <p style="margin-bottom: 6px; display: flex">
                                <span style="padding: 0px 0px 0px 42px">游戏密码：</span><input type="password"
                                    v-model="login_info.password" class="service__input" />
                            </p>
                            <p style="margin-bottom: 6px; display: flex">
                                <span style="padding: 0px 0px 0px 42px">绑定角色：</span>
                                <select v-model="login_info.role" style="width: 154px">
                                    <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                        {{ itmec.charac_name }}
                                    </option>
                                </select>
                                <span style="
                    background: #7478e4;
                    padding: 3px 10px;
                    margin-left: 2px;
                    border-radius: 4%;
                  " @click="getRole">获取</span>
                            </p>
                            <div>
                                <span style="padding: 0px 0px; color: #f90000"><span
                                        style="display: inline-block; padding: 0px 0px">{{
                                        LoginError
                                        }}</span></span>
                            </div>
                            <div style="text-align: center; padding: 10px 0px">
                                <div class="btn" @click="login" style="margin: 0px 0px; display: inline-block">
                                    绑定
                                </div>
                                <div class="btn" @click="LoginDisplay = 'display:none'"
                                    style="margin: 0px 0px; display: inline-block">
                                    取消
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>

            <div class="alert_bg" :style="dhDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>夺宝结果</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <ul class="resultsItme jlitme"></ul>
                            <div style="text-align: center; padding: 10px 0px">
                                <div v-if="click == false" class="btn" @click="DbCloseAlert"
                                    style="margin: 0px 0px; display: inline-block">
                                    确定
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>

            <div class="alert_bg" :style="buyDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <ul class="resultsItme">
                                确定要兑换？
                            </ul>
                            <div style="text-align: center; padding: 10px 0px">
                                <div class="btn" @click="storeGm(buyId)" style="margin: 0px 0px; display: inline-block">
                                    确定
                                </div>
                                <div class="btn" @click="buyDisplay = 'display:none'"
                                    style="margin: 0px 0px; display: inline-block">
                                    取消
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
export default {
    name: "Cloud",
    data () {
        return {
            MessageDisplay: "display:none", //弹出框显示
            LoginDisplay: "display:none", //登陆弹出框
            PayDisplay: "display:none", //购买弹出框
            dhDisplay: "display:none", //兑换弹出框
            buyDisplay: "display:none", //购买弹出框
            LoginError: "",
            MessageMsg: "", //弹出框消息
            Loading: false,
            info: {
                m_id: 0,
                charac_no: 0,
                charac_name: "",
                config: {
                    type: 1, //1刷图模式机会  2充值模式
                    roomdata: [], //刷图列表
                    pay_url: "", //充值地址
                    integralState: 0, //积分开启
                },
                indiana: [], //夺宝商品
                shop: [], //夺宝商城
                indiana_opportunity: 0, //当前剩余机会
                indiana_integral: 0, //当前剩余积分
            },
            cdk: "",
            login_info: {
                username: "",
                password: "",
                role: "",
            },
            typeCheckpoint: "",
            itmeCount: 0,
            itmeCodeLog: "",
            results: [],
            index: 0, //当前转动到哪个位置，起点位置
            count: 0, //总共有多少个位置
            timer: 0, //setTimeout的ID，用clearTimeout清除
            speed: 10, //初始转动速度
            times: 0, //转动次数
            cycle: 20, //转动基本次数：即至少需要转动多少次再进入抽奖环节
            prize: 0, //中奖位置
            role_data: [],
            log: 0,
            msgindex: "", //夺宝结果
            itme_t: null,
            click: false,
            buyId: 0,
            itme_show_data: [], //展示物品用
            init: function (id) {
                if ($("#" + id).find(".lc-prop").length > 0) {
                    var lottery = $("#" + id);
                    var units = lottery.find(".lc-prop");
                    this.obj = lottery;
                    this.count = units.length;
                }
            },
            rollc: function () {
                var index = this.index;
                var count = this.count;
                var lottery = this.obj;
                $(lottery).find(".lc-prop").removeClass("active1");
                index += 1;
                if (index > count - 1) {
                    index = 0;
                }
                $(lottery)
                    .find(".lc-prop-" + this.index)
                    .addClass("active1");
                this.index = index;
                return false;
            },
            stop: function (index) {
                this.prize = index;
                return false;
            },
        };
    },
    created () {
        this.getInfo();
    },
    methods: {
        //登陆
        login () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/PlugLogin",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                    role: this.login_info.role,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.LoginDisplay = "display:none";
                        this.getInfo();
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/getRole",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.role_data = response.data.info;
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //获取基本配置信息
        getInfo () {
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/indiana/getIndianaInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        // this.itmeCount = 0;
                        // this.itmeCodeLog = "";
                        this.info = response.data.info;
                    } else {
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //抽奖
        luckyDraw (type, model) {
            let _this = this;
            if (type == 1) {
                _this.typeCheckpoint = "lotteryBoxs";
            } else if (type == 2) {
                _this.typeCheckpoint = "lotteryBoxsDb";
            } else {
                return;
            }
            if (_this.info.charac_no <= 0) {
                this.PopMessage(type, "请先绑定角色");
                return;
            }
            if (_this.click) {
                return false;
            }

            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/indiana/luckyIndianaDraw",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    lqtype: type,
                    model: model,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.itmeCount = 0;
                        this.itmeCodeLog = "";
                        this.results = response.data.info;
                        this.luckyStart(response.data.type);
                    } else {
                        this.click = false;
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        luckyStart () {
            this.click = true;
            //启动转盘
            let _this = this;
            _this.init(_this.typeCheckpoint);
            _this.speed = 200;
            _this.log = 0;
            _this.itme_t = setInterval(function () {
                if (_this.itmeCount == 0) {
                    _this.itmeCount = 1;
                    setTimeout(function () {
                        _this.fun(_this.log);
                    }, 0);
                }
            }, 1000);
        },
        fun (v) {
            let _this = this;
            var prizeId_data = _this.results;
            _this.log += 1;
            var zt = 0;
            $.each(prizeId_data, function (k) {
                if (k == v) {
                    zt = 1;
                    var prizeId = prizeId_data[k]; //中奖下标
                    var prize_site = prizeId;
                    $("#" + _this.typeCheckpoint).attr("prize_site", prize_site);
                    $("#" + _this.typeCheckpoint).attr(
                        "prize_name",
                        _this.info.indiana[prizeId].ioc
                    );
                    _this.roll();
                }
            });
            if (zt == 0) {
                _this.click = false;
                if (_this.itme_t != null) {
                    clearTimeout(_this.itme_t);
                }
            }
        },
        roll () {
            let _this = this;
            _this.times += 1;
            _this.rollc();
            var prize_site = $("#" + _this.typeCheckpoint).attr("prize_site");
            if (_this.times > _this.cycle + 10 && _this.index == prize_site) {
                clearTimeout(_this.timer);
                setTimeout(function () {
                    var index = _this.index;
                    var lottery = _this.obj;
                    $(lottery).find(".lc-prop").removeClass("active1");
                    $(lottery)
                        .find(".lc-prop-" + index)
                        .addClass("active1");
                    _this.index = 0;

                    //var prize_id    = $("#"+_this.typeCheckpoint).attr("prize_id");
                    var prize_name = $("#" + _this.typeCheckpoint).attr("prize_name");
                    _this.itmeCodeLog += '<li><img src="' + prize_name + '"></li>';
                    _this.itmeCount = 0;
                    _this.dhDisplay = "display:block";
                    itmeLogc(_this.itmeCodeLog);
                    //_this.msgindex = _this.itmeCodeLog

                    _this.prize = -1;
                    _this.times = 0;
                    _this.speed = 100;
                }, _this.speed);
            } else {
                if (_this.times < _this.cycle) {
                    _this.speed -= 20;
                } else if (_this.times == _this.cycle) {
                    var index = (Math.random() * _this.count) | 0;
                    _this.prize = index;
                } else {
                    if (
                        _this.times > _this.cycle + 10 &&
                        ((_this.prize == 0 && _this.index == _this.count - 1) ||
                            _this.prize == _this.index + 1)
                    ) {
                        _this.speed += 90;
                    } else {
                        _this.speed += 30;
                    }
                }
                if (_this.speed < 30) {
                    _this.speed = 30;
                }
                _this.timer = setTimeout(_this.roll, _this.speed);
            }
        },
        //弹出框状态
        PopMessage (type, msg) {
            this.MessageDisplay = "display:none";
            this.MessageMsg = msg;
            if (type == true) {
                this.MessageDisplay = "display:block";
            } else {
                this.getInfo();
            }
        },
        storebuy (id) {
            this.buyId = id;
            this.buyDisplay = "display:block";
        },
        storeGm (id) {
            this.buyDisplay = "display:none";
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/indiana/IndianaStoreBuy",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    id: id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.PopMessage(true, response.data.msg);
                        this.getInfo();
                    } else {
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //兑换
        search () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/indiana/indianaCdkExchange",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    cdk: this.cdk,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.PopMessage(true, response.data.msg);
                    } else {
                        this.PopMessage(true, response.data.msg);
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //关闭窗口
        CloseAlert () {
            this.PopMessage(false, "");
        },
        DbCloseAlert () {
            this.dhDisplay = "display:none";
            this.getInfo();
        },
        lj_info ($event, itmes) {
            this.itme_show_data = itmes;
            var container = document.getElementsByClassName("iteminfo");
            if (typeof container != "undefined") {
                container[0];
                var x = $event.x;
                var y = $event.y;

                if (x > 1167) {
                    console.log("11");
                    container[0].style.left = x - 77 + "px";
                } else {
                    container[0].style.left = x - 73 + "px";
                }
                container[0].style.top = y + "px";
                container[0].style.display = "block";
                let item_h = container[0].offsetHeight + container[0].style.top;
                if (item_h > 435) {
                    y -= item_h - 435;
                    container[0].style.top = y + "px";
                }
            }
        },
        removeActive ($event) {
            console.log($event);
            var container = document.getElementsByClassName("iteminfo");
            if (typeof container != "undefined") {
                container[0].style.display = "none";
            }
        },
    },
};
function itmeLogc (info) {
    $(".jlitme").html("");
    $(".jlitme").html(info);
}
$(function () {
    $(".toptab").click(function () {
        var index = $(this).index();
        $(".page").removeClass("active");
        $(".toptab").removeClass("active");
        $(this).addClass("active");
        $(".page").eq(index).addClass("active");
    });
    $(".midtab").click(function () {
        var index = $(this).index();
        $(".quest-page-item").removeClass("active");
        $(".midtab").removeClass("active");
        $(this).addClass("active");
        $(".quest-page-item").eq(index).addClass("active");
    });
});
</script>

<style scoped>
@import "../../../public/static/plug/indiana/static/css/game.css";
@import "../../../public/static/plug/indiana/static/css/lottery.css";
@import "../../../public/static/plug/indiana/static/css/style.css";
</style>

<style>
#root,
body,
html {
    min-width: 0px;
}
</style>
